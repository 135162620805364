import React from "react";
import { InsiderIconWithGreyBg } from "../../components/ui/icons/InsiderIconWithGreyBg";
import { Music } from "../../components/ui/icons/CategoryIcons/Music";
/**
 * @type {Link}
 * @property {string} resource_type - The type of resource, possible values are: "tag", "event", "article", "category", "external", "group", "go" and "filtered_list"
 * @property {string} [title] - The title of the link.
 * @property {string} [slug] - The slug for the link.
 * @property {string} [url] - The URL of the link (applicable for "external" resource_type).
 * @property {Object} [query_params] - Additional query parameters for the link (applicable for "filtered_list" resource_type).
 */
/**
 * @type {IIconCard}
 * @property {string} title - The title of the quick link.
 * @property {string} subtitle - The subtitle associated with the quick link.
 * @property {string} image - The URL of the image associated with the quick link.
 * @property {string[]} [includeCities] - An array of cities to include for the quick link. Add ["global"] for adding it in all cities. includeCities takes priority over excludeCities
 * @property {string[]} [excludeCities] - An array of cities to exclude for the quick link.
 * @property {Link} link - The object containing params to construct the links
 * @property {boolean} openInNewTab - Boolean set to true to open links in new tab
 */
/**
 * Configuration for the homepage UI components.
 * @type {IIconCard[]}
 */
// this is a temporary config to power the homepage ui components untill the admin flow is built
// note: if more than 3 quicklinks qualify for a city, they will be shuffled
export const quickLinks = [
    //Mumbai
    {
        title: "Ready for this?",
        subtitle: "Gujarat No Navratri",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1725065819/kwgbf1hko0fjldmoazuq.jpg",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "rangilo-re-utsav-gujarat-no-navratri-2024"
        }
    },
    {
        title: "Radiance Dandiya",
        subtitle: "Jio World Convention",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1726283889/vuaghxv2tyhxlohnqiez.jpg",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "radiance-dandiya-navratri-2024"
        }
    },
    {
        title: "Navratri at MBCA",
        subtitle: "BKC MMRDA Ground",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284048/dn4o97zscxcpmctvjktb.jpg",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "mbca-dandiya-night-navratri-2024"
        }
    },
    //Delhi
    {
        title: "Rock n' Dhol 2024",
        subtitle: "Dhyan Chand National Stadium",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284352/afop0dgj8d5sttbacoy4.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "shubharambh-delhis-biggest-disco-dandiya-festival-navratri-2024"
        }
    },
    {
        title: "Hozho in Delhi",
        subtitle: "Diablo Delhi, Sept 22nd",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284408/degwc5hqv9jzube98bfr.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "hozho-live-in-delhi-sep22-2024"
        }
    },
    {
        title: "The Grub Fest",
        subtitle: "In Delhi this September",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1725065980/zbxa9uoi3qsgirmy4wbp.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "the-grub-fest-autumn-bliss-2024-sep28-2024"
        }
    },
    //Bengaluru
    {
        title: "Hozho in Bengaluru",
        subtitle: "Sunburn Union, Sept 21st ",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284442/lglo0nbqbqyyvpgdkh15.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "hozho-live-at-sunburn-union-sep21-2024"
        }
    },
    {
        title: "This December!",
        subtitle: "Echoes of Earth 2024",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1723436709/hqljpchmapfeoiz0tvis.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "echoesofearth2024bangalore"
        }
    },
    {
        title: "Soul Sante Flea",
        subtitle: "Boholove Edition",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724416929/wdt6ogyaxia9ohgksthu.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "sunday-soul-sante-boholove-edition-sep22-2024"
        }
    },
    //Pune
    {
        title: "Empathy Experiment",
        subtitle: "Unshared Childhoods",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284596/nto2emdstilkwzsexnwz.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "unshared-childhoods-pottery-studio-farmhouse"
        }
    },
    {
        title: "Ready for this?",
        subtitle: "Gujarat No Navratri",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1725066380/a48m9zrprf9i8zv1b25y.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "rangilo-re-utsav-gujarat-no-navratri-2024"
        }
    },
    {
        title: "Get. set. Garba!",
        subtitle: "Navratri 2024",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724038696/hkdgnwdruvtwhg9fkzfz.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "dandio-ki-raas-leela-navratri-2024"
        }
    },
    //Goa
    {
        title: "Nox Vahn LIVE",
        subtitle: "At Artistry Goa",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1725066715/sz9sy49sfvfhwuwaux8m.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "nox-vahn-at-artistry-goa-sep21-2024"
        }
    },
    {
        title: "3 day wellness fest",
        subtitle: "In Goa this November",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1725066746/pzjvpivvopkn1eyfw4tj.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "bliss-tree-experience-goa-2024-nov7-2024"
        }
    },
    {
        title: "Offbeat Vibes",
        subtitle: "In Goa with Aguad",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1725066772/tkpeuvqohn20fj3y0519.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "aguad-goa"
        }
    },
    //Hyderabad
    {
        title: "Double Treat",
        subtitle: "Dreamhack x Comic Con",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1725812939/fhsxpk4ikpqisxicfyr8.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "dreamhack-hyderabad-comic-con-2024-nov15-2024"
        }
    },
    {
        title: "EXT Turns 2",
        subtitle: "Indeed Weekender",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284712/gdevlbbwhf4zovndnfd5.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "indeed-weekender-2024-sep20-2024"
        }
    },
    {
        title: "Greatest Hits!",
        subtitle: "Geetha Madhuri, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284768/fxq8r4au1e7jshajbtay.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "geetha-madhuri-live-at-grease-monkey-3rd-anniversary-sep21-2024"
        }
    },
    //Chennai
    {
        title: "IND vs BAN",
        subtitle: "IDFC FIRST Bank Series",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284805/mtiidjb4uwkct489ovwc.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "idfc-first-bank-series-1st-test-india-vs-bangladesh-chennai-sept19th-23rd-2024"
        }
    },
    {
        title: "Raas Garba Workshop",
        subtitle: "Tradition meets rhythm",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284048/dn4o97zscxcpmctvjktb.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "raas-garba-workshop-2024-navratri-2024"
        }
    },
    {
        title: "Avatar LIVE",
        subtitle: "For the 1st Time",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1725067070/m1xf13j2n7c1dwak92ev.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "the-avatar-experience-aug24-2024"
        }
    },
    //Kolkata
    {
        title: "Pujor Passport '24",
        subtitle: "Go Pandal Hopping",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1726285603/ehvb0pomijalnyycpr1n.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "durga-pujor-passport-2024-i-forum-for-durgotsab-durga-puja-2024"
        }
    },
    {
        title: "Republic of Rock",
        subtitle: "Rock out, horns out!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1725813020/dz22fbru4tbnreyq5zs2.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "republic-of-rock-2024-i-kolkata-nov9-2024"
        }
    },
    {
        title: "Queen of Melody",
        subtitle: "Shreya Ghoshal Live",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1725856605/otochejmalarfotkjqvo.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "ishq-fm-grand-concert-shreya-ghoshal-live-kolkata-oct19-2024"
        }
    },
    //coimbatore
    {
        title: "The G.O.A.T.",
        subtitle: "Yuvan S. Raaja, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1726285664/p30a8s0dat5yp913qdqn.jpg",
        includeCities: ["Coimbatore"],
        link: {
            resource_type: "event",
            slug: "yuvan-shankar-raaja-the-goat-live-in-coimbatore-oct-12"
        }
    },
    {
        title: "HipHop Tamizha",
        subtitle: "LIVE, on Sept 21st",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1726285711/rp9nilikcehyo7ugxs5j.jpg",
        includeCities: ["Coimbatore"],
        link: {
            resource_type: "event",
            slug: "return-of-the-dragon-machi-home-edition-sep21-2024-chennai"
        }
    },
    {
        title: "Karthik Raaja",
        subtitle: "And more legends, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724470597/wh7dqd6bdfkyaleak7go.jpg",
        includeCities: ["Coimbatore"],
        link: {
            resource_type: "event",
            slug: "king-of-kings-2024-karthik-raaja-live-in-concert-coimbatore-oct19-2024"
        }
    },
    //visakhapatnam
    {
        title: "Top Lesi Poddi",
        subtitle: "Rockstar DSP, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039096/yn8obun5ohhi2e2albrt.jpg",
        includeCities: ["Visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "rockstar-dsp-live-in-hyderabad-oct19-2024"
        }
    },
    {
        title: "Chale Aana",
        subtitle: "See Armaan Malik, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039123/dmb784vsdxfcypyyvi5i.jpg",
        includeCities: ["Visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "armaan-malik-live-in-vizag-nov9-2024"
        }
    },
    {
        title: "Agam LIVE",
        subtitle: "In Hyderabad",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1723437217/elyap4jxtm1thelsirns.jpg",
        includeCities: ["Visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "agam-live-in-hyderabad-sep21-2024"
        }
    },
    //vizag
    {
        title: "Top Lesi Poddi",
        subtitle: "Rockstar DSP, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039096/yn8obun5ohhi2e2albrt.jpg",
        includeCities: ["Vizag"],
        link: {
            resource_type: "event",
            slug: "rockstar-dsp-live-in-hyderabad-oct19-2024"
        }
    },
    {
        title: "Chale Aana",
        subtitle: "See Armaan Malik, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039123/dmb784vsdxfcypyyvi5i.jpg",
        includeCities: ["Vizag"],
        link: {
            resource_type: "event",
            slug: "armaan-malik-live-in-vizag-nov9-2024"
        }
    },
    {
        title: "Agam LIVE",
        subtitle: "In Hyderabad",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1723437217/elyap4jxtm1thelsirns.jpg",
        includeCities: ["Vizag"],
        link: {
            resource_type: "event",
            slug: "agam-live-in-hyderabad-sep21-2024"
        }
    }
];
export const placeholderQuickLinks = [
    {
        title: "Host an Event",
        subtitle: "Make your event live in under 5 mins",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "external",
            url: "/list-your-events"
        }
    },
    {
        title: "Events this weekend",
        subtitle: "Events this weekend",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "everything",
            time_filter: "weekend"
        }
    }
];
export const mobilePlaceHolderLinks = [
    {
        title: "Download our app now!",
        subtitle: "Android & iOS",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "external",
            url: "https://link.insider.in/app"
        }
    },
    ...placeholderQuickLinks
];
/**
     Key to be added for iOs on categoryBanners array:
     * hideForIOS : true if we don't want to show banner on iOs devices
     * showForIOS : true if we want to to show a different banenr for iOs device, create another object named categoryBannersForIOs with same format
     * * don't make both true
     * * No need to add above keys on categoryBannersForIOs, adding keys (if required) on original config array is sufficient.
     * * Don't add any key if not required
     */
export const categoryBannersForIOs = [];
export const categoryBanners = [
    //Category Banner
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284099/i1wzw5njjwgl9yd1a3rn.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284123/no14hyqpmlrbtwdjllte.jpg",
        includeCities: ["mumbai"],
        link: {
            resource_type: "event",
            slug: "jwrm-presents-diorange-live-mumbai-sep29-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1726458061/gyzkmfhp3kk2dhu7pfna.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1726458065/p7dajibrobpg1zbbhnly.jpg",
        includeCities: ["delhi"],
        link: {
            resource_type: "event",
            slug: "jazz-weekender-2024-international-jazz-festival"
        }
    },
    {
        categoryId: "669e40ebd06b355867268b69",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1725812734/sf4s21imq1ds83kweaaq.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1725812765/vjbd4v34nfxvm9kca8pr.jpg",
        includeCities: ["bengaluru"],
        link: {
            resource_type: "event",
            slug: "marathahalli-dandiya-utsav-marathahalli-biggest-dandiya-oct5-2024-navratri-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1725812805/aqqieeggcprolrqlg2tm.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1725812831/etqlo1xnfri3imnfxiod.jpg",
        includeCities: ["pune"],
        link: {
            resource_type: "event",
            slug: "casa-bacard-on-tour-ft-talwiinder-pune"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724470437/arh8fzpdpjsqnrtfl5sd.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724470433/fqus868pawbnvta5vl73.jpg",
        includeCities: ["hyderabad"],
        link: {
            resource_type: "event",
            slug: "agam-live-in-hyderabad-sep21-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284650/uzyvnmqgwphacr2b9vkx.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284646/sakumir9r4y4xjprvbow.jpg",
        includeCities: ["goa"],
        link: {
            resource_type: "event",
            slug: "pumproom-x-centigrade-ft-my-friend-21st-sep-vamos-goa-sep21-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284935/b8jfds6qqvycuwgjhim8.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284925/fxlfw2llaa9kr6kmt0up.jpg",
        includeCities: ["chennai"],
        link: {
            resource_type: "event",
            slug: "yuvan-shankar-raaja-the-goat-live-in-coimbatore-oct-12"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1725813090/ugxriry4dsjmiamd7q7f.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1725813064/scjm8jlioj86gk43wh8b.jpg",
        includeCities: ["kolkata"],
        link: {
            resource_type: "event",
            slug: "vishal-mishra-kolkata-oct19-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1726285770/i0imzowp07d8xllaen2b.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1726285762/l3jzecnhhihqvsssftwp.jpg",
        includeCities: ["coimbatore"],
        link: {
            resource_type: "event",
            slug: "yuvan-shankar-raaja-the-goat-live-in-coimbatore-oct-12"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039161/wieerk3ojaqv5auxigy6.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039166/ccvsttnb57ddne4x0bju.jpg",
        includeCities: ["visakhapatnam", "vizag"],
        link: {
            resource_type: "event",
            slug: "armaan-malik-live-in-vizag-nov9-2024"
        }
    }
];
export const showcase = [
    // Type B
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1723437156/tegvlohjwrzfuftqo44o.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1723437160/yhdr4vaqmlnjjemq9ij1.jpg",
        categoryIcon: <Music />,
        heading: "PCRC LIVE!",
        subheading: "Enter the musical ‘Portrait Of A Time’ with Peter Cat Recording Co.",
        caption: "The band is coming to your city with their ‘Good Luck Beta’ tour! Brace yourselves for a mind-blowing performance and fresh new tracks!",
        cta: "Book Now",
        includeCities: ["goa", "pune", "kolkata"],
        cardListType: "schedule-card",
        tag: "good-luck-beta-tour",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "microsite/pcrc-goodluckbeta24"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1722742997/exoixzrbnfumfmgmicrz.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1722742991/mgfcfuifxo0txghfquv4.jpg",
        categoryIcon: <Music />,
        heading: "Welcome to the Jungle",
        subheading: "ZAMNA is coming to India!",
        caption: "The internationally acclaimed ZAMNA Festival is making its debut in India, and the excitement is off the charts.",
        cta: "Book Now",
        includeCities: ["delhi"],
        cardListType: "schedule-card",
        tag: "zamna-festival-2024",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "/microsite/zamna-india-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284195/mj3gdhnqkitjxkjsbfn5.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284202/szsiplxmymbpry1h3exb.jpg",
        categoryIcon: <Music />,
        heading: "Innellea India tour 2024",
        subheading: "Artist behind productions on Afterlife like 'Vigilans' & 'The World Returns'",
        caption: "Innellea’s visions & impressions are transferred into music. Influenced by melodic trends, he combines the roots of Techno to produce a unique & abstract electronic sound.",
        cta: "Book Now",
        includeCities: ["mumbai", "bengaluru"],
        cardListType: "schedule-card",
        tag: "innellea-the-belonging-tour-2024",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "go",
            slug: "innellea-the-belonging-tour-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    //type A
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284999/yjyuwel1lj6l9qsxs0ui.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1726285003/bhhsthkhycasjqh28wyn.jpg",
        categoryIcon: <Music />,
        heading: "Yuvan Shankar LIVE!",
        subheading: "Experience Yuvan Shankar Raja Live: The G.O.A.T. in Coimbatore!",
        caption: "Join us in Coimbatore for an unforgettable night with Yuvan Shankar Raja, the G.O.A.T. of music. Live performance, exceptional vibes!",
        cta: "Buy Now",
        includeCities: ["chennai", "coimbatore"],
        link: {
            resource_type: "event",
            slug: "yuvan-shankar-raaja-the-goat-live-in-coimbatore-oct-12"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724225442/hy0evrvrfjqtpjcst8na.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724225439/fijvxhp4z4s7gsrtaeo2.jpg",
        categoryIcon: <Music />,
        heading: "First time in Vizag!",
        subheading: "Catch Armaan Malik LIVE this November!",
        caption: "Known for his soulful voice and chart-topping hits - from romantic ballads to foot-tapping numbers, experience the magic of his music up close and personal.",
        cta: "Buy Now",
        includeCities: ["visakhapatnam", "vizag"],
        link: {
            resource_type: "event",
            slug: "armaan-malik-live-in-vizag-nov9-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1725066925/a39rbunmazmrtm8ex8aq.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1725066936/rclhjm3znpqydxtmnpz7.jpg",
        categoryIcon: <Music />,
        heading: "Rockstar DSP LIVE!",
        subheading: "Watch him perform in Hyderabad to kickstart his India Tour!",
        caption: "An excellent dancer. A versatile composer. A chart-topper. DSP infuses his songs with irresistible dance spirits! Get ready to feel the vibes LIVE.",
        cta: "Buy Now",
        includeCities: ["hyderabad"],
        link: {
            resource_type: "event",
            slug: "rockstar-dsp-live-in-hyderabad-oct19-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    }
];
